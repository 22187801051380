var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", "background-color": "#ffffff" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(1)
          },
          "head-submit": _vm.headSubmit,
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _vm.templateClassify == "1"
          ? _c(
              "div",
              { staticClass: "formMain" },
              [_c("preview", { ref: "preview" })],
              1
            )
          : _c("div", { staticClass: "iframeBox" }, [
              _vm.iframeShow
                ? _c("iframe", {
                    ref: "iframe",
                    staticClass: "iframe",
                    staticStyle: { border: "none" },
                    attrs: { src: _vm.reportReviewPath },
                  })
                : _vm._e(),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }