<template>
  <div style="height: 100%;background-color: #ffffff">
    <head-layout
      :head-title="headTitle"
      :head-btn-options="headBtnOptions"
      @head-save="headSave(1)"
      @head-submit="headSubmit"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain" v-if="templateClassify == '1'">
        <preview ref="preview"></preview>
      </div>
      <div class="iframeBox" v-else>
        <iframe v-if="iframeShow" ref="iframe" class="iframe" :src="reportReviewPath" style="border: none"></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import {
  rpTaskAttributeDetail,
  rpTaskAttributeView,
  dictionaryBiz,
  rpTaskAttributeSaveSummarize, rpTaskAttributeDetailReport,
  rpTaskAttributeReportClick
} from "@/api/reportWrite";
import {getDetailByTaskId} from "@/api/reportTemplate";
import preview from "@/views/business/reportManager/components/preview";
import draft from "@/views/plugin/workflow/mixins/draft";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import {dateFormat} from "@/util/date";
import {mapGetters} from "vuex";

export default {
  components: {
    preview,
  },
  data() {
    return {
      formShow: false,
      iframeShow: false,
      headTitle: '',
      fullName: '',
      weaveDept: '',
      templateClassify: '',
      reportReviewPath: '',
      dataForm: {},
      form: {
        status: 1,
      },
      formProcess: {
        id: '',
        processId: '',
        data: {},
        url: ''
      },
      disabled: false,
      submitLoading: true,
      formOption: [],
      type: '',
      templateCode: '',
      processTemplateKey: "reportReview",
      processBusinessName: "",
    }
  },
  mixins: [exForm, draft],
  computed: {
    ...mapGetters(['userInfo']),
    headBtnOptions() {
      let result = [];
      if (['add', 'edit', 'workflow'].includes(this.type) && (this.form.status === 1 || this.form.status === 3)) {
        result.push(
          {
            label: "保存",
            emit: "head-save",
            type: "button",
            btnOptType: "save",
          }
        );
      }
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: "提交",
            emit: "head-submit",
            type: "button",
            btnOptType: "submit",
            loading: this.submitLoading,
          }
        );
      }
      if (!["workflow"].includes(this.type)) {
        result.push(
          {
            label: "取消",
            emit: "head-cancel",
            type: "button",
            btnOptType: "cancel",
          }
        );
      }
      return result;
    },
  },
  mounted() {
    const taskCode = this.$route.query.taskId;
    //暂时注释不影响业务
    this.templateClassify = this.$route.query.templateClassify;
    if (taskCode === undefined) {
      let type = this.$route.query.type;
      this.templateCode = this.$route.query.templateCode;
      this.type = type;
      this.fullName = this.$route.query.fullName;
      this.weaveDept = this.$route.query.weaveDept;
      if (type == 'edit') {
        // this.headTitle = '填报';
        this.getProcessId();
        this.form.id = this.$route.query.id;
        this.getAttributeDetail();
      }
      if (type == 'view') {
        // this.headTitle = '查看';
        this.form.id = this.$route.query.id;
        this.getAttributeView();
      }
      if (type === 'workflow') {
        this.headTitle = '报表审核';
        this.form.id = this.$route.query.id;
        this.getAttributeView();
      }
    } else {
      getDetailByTaskId(taskCode).then(res => {
        this.weaveDept = res.data.data.weaveDept;
        this.type = 'edit'
        this.form.id = res.data.data.id;
        this.fullName = res.data.data.fullName;
        this.templateCode = res.data.data.templateCode;
        this.getProcessId();
        this.getAttributeDetail();
      })
    }
  },
  methods: {
    //保存
    headSave(number) {
      if (this.templateClassify == '1') {
        this.$refs.preview.$refs.dataForm.validate((valid) => {
          if (valid) {
            this.$loading();
            this.dataForm.dataStatus = number;
            this.form.templateCode = this.templateCode;
            this.form.templateClassify = this.templateClassify;
            this.form.dataStatus = number;
            this.form.rpTaskAttributeMap = this.$refs.preview.dataForm;
            rpTaskAttributeSaveSummarize(this.form).then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.$loading().close();
              }
            })
          }
        });
      } else {
        this.$loading();
        this.form.templateCode = this.templateCode;
        this.form.dataStatus = number;
        this.form.templateClassify = this.templateClassify;
        rpTaskAttributeSaveSummarize(this.form).then((res) => {
          if (res.data.code === 200) {
            let iframe = this.$refs.iframe
            iframe.contentWindow.postMessage('save', '*')
            console.log("调用保存接口")
            // this.$message({
            //   message: "操作成功",
            //   type: "success",
            // });
            this.$loading().close();
          }
        })
      }
    },
    //提交
    async headSubmit() {
      if (this.templateClassify == '1') {
        let valid = await this.$refs.form.validate().catch((err) => {
          return err
        })
        if (!valid) return
      }
      this.$loading();
      this.disabled = true;
      this.type = 'view'
      this.$refs.preview.disabled = this.disabled;
      this.formProcess.id = this.form.id
      this.formProcess.data = this.$refs.preview.dataForm
      this.formProcess.deptId = this.userInfo.dept_id
      this.formProcess.processBusinessName = this.processBusinessName
      this.formProcess.templateClassify = this.templateClassify;
      this.handleStartProcess5(true).then(async (res) => {
        this.form.templateCode = this.templateCode;
        this.form.dataStatus = 2;
        this.form.rpTaskAttributeMap = this.$refs.preview.dataForm;
        this.form.status = 2;
        this.form.templateClassify = this.templateClassify;
        this.form.processInstanceId = res.data.data;
        await rpTaskAttributeSaveSummarize(this.form).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.$loading().close();
            this.$router.back();
          }
        })
          if (this.templateClassify == '1') {
            let iframe = this.$refs.iframe
            iframe.contentWindow.postMessage('save', '*')
            this.iframeShow = false;
          }
      });
    },
    //取消
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    //查看详情
    getAttributeView() {
      rpTaskAttributeView(this.form.id).then((res) => {
          let dataForm = {};
          if (res.data.code === 200) {
            this.form = res.data.data
            if (this.templateClassify == '1') {
              dataForm = this.form.dataForm
              if (this.type !== 'workflow') {
                this.headTitle = this.weaveDept + "-" + res.data.data.title;
              }
              if (this.templateCode === undefined) {
                this.templateCode = this.form.templateCode;
              }
              this.processBusinessName = res.data.data.title;
              let data = this.form.properties;
              data.forEach((item) => {
                if (item.columnNum == 1) {
                  item.span = 24;
                }
                if (item.columnNum == 2) {
                  item.span = 12;
                }
                if (item.columnNum == 3) {
                  item.span = 8;
                }
                if (item.columnNum == 4) {
                  item.span = 6;
                }
                item.properties.forEach((i) => {
                  if (i.type == '1' || i.type == '2') {
                    if (!dataForm[i.fieldCode]) {
                      dataForm[i.fieldCode] = undefined;
                    }
                  } else {
                    if (!dataForm[i.fieldCode]) {
                      dataForm[i.fieldCode] = '';
                    }
                  }
                  if (i.type == '5') {
                    dictionaryBiz(i.dictionaryCode).then((res) => {
                      i.dictionaryOption = res.data.data
                    });
                  }
                })
              })
              setTimeout(() => {
                this.$refs.preview.formOption = data;
                this.$refs.preview.type = 'write';
                this.$refs.preview.dataForm = dataForm;
                this.$refs.preview.disabled = this.disabled;
                this.$refs.preview.previewShow = true;
              }, 1000)
              if (this.type === 'workflow' && (this.form.status === 2 || this.form.status === 4)) {
                this.disabled = true;
                this.$refs.preview.disabled = this.disabled;
              }
            } else {
              if (this.type !== 'workflow') {
                this.headTitle = this.weaveDept + "-" + this.form.title;
              }
              this.processBusinessName = this.form.title;
              rpTaskAttributeDetailReport(this.form.templateCode).then((res) => {
                let dataForm = res.data.data;
                let date = this.time = dateFormat(new Date(), 'yyyy-MM-dd');
                if (this.form.acquisitionTaskType == 'summary') {
                  rpTaskAttributeReportClick(this.form).then((res) => {
                    if (res.data.code == 200) {
                      let data = res.data.data;
                      if (this.type === 'workflow' && this.form.status != 2 && this.form.status != 4) {
                        this.reportReviewPath = dataForm.reportFillPath + '&create_date=' + data.create_date + '&id=' + data.id + '&type=' + data.type + '&create_dept=' + this.userInfo.dept_id;
                        if (data.ids) {
                          this.reportReviewPath = this.reportReviewPath + '&ids=' + data.ids
                        } else {
                          this.reportReviewPath = this.reportReviewPath + '&ids=null'
                        }
                      } else {
                        this.reportReviewPath = dataForm.reportReviewPath + '&create_date=' + date + '&id=' + this.form.id + '&type=0' + '&create_dept=' + this.userInfo.dept_id;
                      }
                      this.iframeShow = true;
                    }
                  })
                } else {
                  if (this.type == 'edit' || (this.type === 'workflow' && this.form.status != 2 && this.form.status != 4)) {
                    this.reportReviewPath = dataForm.reportFillPath + '&create_date=' + date + '&id=' + this.form.id + '&type=0' + '&create_dept=' + this.userInfo.dept_id;
                  } else {
                    this.reportReviewPath = dataForm.reportReviewPath + '&create_date=' + date + '&id=' + this.form.id + '&type=0' + '&create_dept=' + this.userInfo.dept_id;
                  }
                }
                this.iframeShow = true;
              })
            }
          }
        }
      )
    },
    // 根据ID查表单详情
    getAttributeDetail() {
      rpTaskAttributeDetail(this.form.id).then((res) => {
        let dataForm = new Object();
        if (res.data.code == 200) {
          if (this.templateClassify == '1') {
            if (this.type !== 'workflow') {
              this.headTitle = this.weaveDept + "-" + res.data.data.title;
            }
            dataForm = res.data.data.dataForm;
            this.processBusinessName = res.data.data.title;
            let data = res.data.data.properties;
            data.forEach((item) => {
              if (item.columnNum == 1) {
                item.span = 24;
              }
              if (item.columnNum == 2) {
                item.span = 12;
              }
              if (item.columnNum == 3) {
                item.span = 8;
              }
              if (item.columnNum == 4) {
                item.span = 6;
              }
              item.properties.forEach((i) => {
                if (i.type == '1' || i.type == '2') {
                  if (!dataForm[i.fieldCode]) {
                    dataForm[i.fieldCode] = undefined;
                  }
                } else {
                  if (!dataForm[i.fieldCode]) {
                    dataForm[i.fieldCode] = '';
                  }
                }
                if (i.type == '5') {
                  dictionaryBiz(i.dictionaryCode).then((res) => {
                    i.dictionaryOption = res.data.data
                  });
                }
              })
            })
            setTimeout(() => {
              this.$refs.preview.formOption = data;
              this.$refs.preview.type = 'write';
              this.$refs.preview.dataForm = dataForm;
              this.$refs.preview.disabled = this.disabled;
              this.$refs.preview.previewShow = true;
            }, 1000)
          } else {
            this.form = res.data.data;
            if (this.type !== 'workflow') {
              this.headTitle = this.weaveDept + "-" + this.form.title;
            }
            rpTaskAttributeDetailReport(this.form.templateCode).then((res) => {
              this.processBusinessName = this.form.title;
              let date = this.time = dateFormat(new Date(), 'yyyy-MM-dd');
              if (this.type == 'edit' || (this.type === 'workflow' && this.form.status != 2 && this.form.status != 4)) {
                this.reportReviewPath = res.data.data.reportFillPath + '&create_date=' + date + '&id=' + this.form.id + '&type=0&create_dept=' + this.userInfo.dept_id;
              } else {
                this.reportReviewPath = res.data.data.reportReviewPath + '&create_date=' + date + '&id=' + this.form.id + '&type=0&create_dept=' + this.userInfo.dept_id;
              }
              this.iframeShow = true;
            })
          }
        }
      })
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = "填报信息";
        this.formProcess.processId = process.id;
      });
    }
    ,
  }
}
</script>
<style lang="scss" scoped>
.formBox {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.formTitleBox {
  width: calc(100% - 24px);
  border-bottom: 1px solid #bbbbbb;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.leftBack {
  cursor: pointer;
  display: flex;
}

.backBox {
  position: relative;
  line-height: 28px;
}

.backBox:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 16px;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #dcdfe6;
}

.rightBtn {
  display: flex;
}

.formContentBox {
  padding: 12px;
  height: calc(100% - 94px);
}

.formMain {
  width: 100%;
  height: 100%;
  overflow: auto;
  height: calc(100% - 28px);
}

.iframeBox {
  width: 100%;
  height: 100%;
}

.iframe {
  width: 100%;
  height: 100%;
}
</style>
